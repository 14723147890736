<template>
  <div>
    <v-app-bar
      app
      flat
      :class="{ desktop: !$vuetify.breakpoint.mobile }"
      color="appbar"
    >
      <v-app-bar-nav-icon
        @click="changeDrawerLeft"
        color="primary"
      ></v-app-bar-nav-icon>
      <v-btn
        icon
        @click="changeDrawerRight"
        v-if="iconDrawerRight"
        color="primary"
      >
        <v-icon>mdi-layers</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-list dense class="pa-0" color="appbar">
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="primary">
                  <v-icon dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title icon v-bind="attrs" v-on="on"
                >{{ fullName }}
                <v-btn icon color="primary">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
        <v-list outlined class="pa-0">
          <v-list-item>
            <v-list-item-title
              @click="settingsPage"
              style="text-align: right"
              icon
              :style="{ cursor: 'pointer' }"
            >
              Настройки
              <v-btn icon color="primary">
                <v-icon>mdi-cogs</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              @click="logout()"
              style="text-align: right"
              icon
              :style="{ cursor: 'pointer' }"
            >
              Выход
              <v-btn icon color="primary">
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item-action>
        <v-btn rounded elevation="0" outlined color="primary" @click="toggleTheme">Изменить тему</v-btn>
      </v-list-item-action>
    </v-app-bar>
    <v-navigation-drawer
      width="450"
      height="100%"
      v-model="drawerLeft"
      bottom
      :expand-on-hover="!$vuetify.breakpoint.mobile && expand"
      fixed
    >
      <v-card-text class="text-end">
        <v-btn icon :class="{ hide: expand }" @click="closeDrawerLeft">
          <v-icon size="32">mdi-close-circle-outline</v-icon>
        </v-btn></v-card-text
      >
      <v-img
        v-if="this.$vuetify.theme.dark"
        src="@/assets/logo_white.svg"
        transition="scale-transition"
        height="40"
        contain
      />
      <v-img
        v-else
        src="@/assets/logo.png"
        transition="scale-transition"
        height="40"
        contain
      />
      <v-list shaped>
        <v-list-group v-if="$store.state.user.userRole !== 3">
          <template v-slot:activator>
            <v-list-item-icon>
              <iconify-icon icon="chart" height="24" />
            </v-list-item-icon>
            <v-list-item-title>Отчеты</v-list-item-title>
          </template>
          <template>
            <v-list-item
              to="/main/action_log"
              link
              v-if="
                $store.state.user.userRole !== 2 &&
                  $store.state.user.userRole !== 4 &&
                  $store.state.user.userRole !== 5
              "
            >
              <v-list-item-icon />
              <v-list-item-title>Журнал действий</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/main/requests_portal"
              link
              v-if="$store.state.user.userRole === 1"
            >
              <v-list-item-icon />
              <v-list-item-title>Обращение горожан</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/main/facility_reports"
              link
              v-if="$store.state.user.userRole !== 3"
            >
              <v-list-item-icon />
              <v-list-item-title>Отчеты по объектам</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>

        <div
          v-if="
            $store.state.user.userRole !== 2 &&
              $store.state.user.userRole !== 3 &&
              $store.state.user.userRole !== 4 &&
              $store.state.user.userRole !== 5
          "
        >
          <v-list-group
              v-for="option in options"
              :key="option.title"
              :value="option.action"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <iconify-icon :icon="option.icon" height="24" />
              </v-list-item-icon>
              <v-list-item-title>{{ option.title }}</v-list-item-title>
            </template>

            <!-- Nested v-list-group for each subsection -->
            <v-list-group
                v-for="(subsection, index) in option.sections"
                :key="index"
                sub-group
                no-action
            >
              <template v-slot:activator>
                <v-list-item-icon />
                <v-list-item-title>{{ subsection.title }}</v-list-item-title>
              </template>

              <v-list-item
                  v-for="(item, i) in subsection.sections"
                  :key="i"
                  :to="item.route"
                  link
              >
                <v-list-item-icon />
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </div>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-icon>
              <iconify-icon icon="map" height="24" />
            </v-list-item-icon>
            <v-list-item-title>Работа с картой</v-list-item-title>
          </template>
          <template>
            <v-list-item to="/main/map" link>
              <v-list-item-icon />
              <v-list-item-title>Объекты зеленых насаждений</v-list-item-title>
            </v-list-item>
            <v-list-item to="/main/map2" link>
              <v-list-item-icon />
              <v-list-item-title>Водные объекты</v-list-item-title>
            </v-list-item>
            <v-list-item to="/main/map3" link>
              <v-list-item-icon />
              <v-list-item-title>Воздух</v-list-item-title>
            </v-list-item>
            <v-list-item to="/main/map4" link>
              <v-list-item-icon />
              <v-list-item-title>Почва</v-list-item-title>
            </v-list-item>
            <v-list-item to="/main/map5" link>
              <v-list-item-icon />
              <v-list-item-title>Коммунальные отходы</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$store.state.user.userRole !== 5"
              to="/main/improvement"
              link
            >
              <v-list-item-icon />
              <v-list-item-title>Объекты благоустройства</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/main/districts"
              link
              v-if="
                $store.state.user.userRole !== 2 &&
                  $store.state.user.userRole !== 3 &&
                  $store.state.user.userRole !== 4 &&
                  $store.state.user.userRole !== 5
              "
            >
              <v-list-item-icon />
              <v-list-item-title>Районы</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/main/clustergrid"
              link
              v-if="
                $store.state.user.userRole !== 3 &&
                  $store.state.user.userRole !== 4 &&
                  $store.state.user.userRole !== 5
              "
            >
              <v-list-item-icon />
              <v-list-item-title>Сетка кластеризации</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/main/taxatorgrid"
              link
              v-if="
                $store.state.user.userRole !== 3 &&
                  $store.state.user.userRole !== 4 &&
                  $store.state.user.userRole !== 5
              "
            >
              <v-list-item-icon />
              <v-list-item-title>Сетка таксаторов</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/main/sanitarygrid"
              link
              v-if="
                $store.state.user.userRole !== 3 &&
                  $store.state.user.userRole !== 5
              "
            >
              <v-list-item-icon />
              <v-list-item-title>Сетка санитарной обработки</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-group
          v-if="
            $store.state.user.userRole !== 2 &&
              $store.state.user.userRole !== 3 &&
              $store.state.user.userRole !== 4 &&
              $store.state.user.userRole !== 5
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <iconify-icon icon="people" height="24" />
            </v-list-item-icon>
            <v-list-item-title>Управление</v-list-item-title>
          </template>
          <template>
            <v-list-item to="/main/users" link>
              <v-list-item-icon />
              <v-list-item-title>Пользователи</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawerRight" bottom right fixed width="300">
      <v-list>
        <v-list-item
          ><v-list-item-icon>
            <v-icon size="36">mdi-layers</v-icon></v-list-item-icon
          ><v-list-item-title
            ><h3 class="text-wrap">
              Управление отображением
            </h3></v-list-item-title
          ><v-list-item-action>
            <v-btn icon @click="changeDrawerRight">
              <v-icon size="32">mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <draggable v-model="maps">
          <v-list-item v-for="item in maps" :key="item.name">
            <v-container class="ma-0 pa-0">
              <v-checkbox
                v-model="item.visible"
                :label="item.name"
              ></v-checkbox>
              <v-slider
                v-model="item.alpha"
                min="0"
                max="100"
                :thumb-size="16"
                thumb-label="always"
                thumb-color="green darken-1"
              ></v-slider>
            </v-container>
          </v-list-item>
        </draggable>
      </v-list>
    </v-navigation-drawer>

    <v-main :class="{ desktop: !$vuetify.breakpoint.mobile }">
      <v-divider></v-divider>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import IconifyIcon from "@iconify/vue";
import draggable from "vuedraggable";
import chart from "@iconify/icons-fa-solid/chart-pie";
import book from "@iconify/icons-raphael/book";
import map from "@iconify/icons-fa-solid/map-marked";
import people from "@iconify/icons-raphael/people";

IconifyIcon.addIcon("chart", chart);
IconifyIcon.addIcon("book", book);
IconifyIcon.addIcon("map", map);
IconifyIcon.addIcon("people", people);

export default {
  name: "MainScreen",

  components: {
    IconifyIcon,
    draggable
  },

  data() {
    return {
      fullscreen: false,
      drawerLeft: false,
      drawerRight: false,
      iconDrawerRight: false,
      mini: false,
      expand: true,
      maps: this.$store.state.settings.maps,
      fullName: "",
      options: [
        {
          title: "Справочники",
          icon: "book",
          action: false,
          sections: [
            {
              title: "Зеленые насаждения",
              sections: [
                { title: "Слои", route: "/main/layers" },
                { title: "Объекты на карте", route: "/main/objects_map" },
                { title: "Порода", route: "/main/breeds" },
                { title: "Кустарники", route: "/main/bushes" },
                { title: "Цветы", route: "/main/flowers" },
                { title: "Виды лиан", route: "/main/vines" },
                { title: "Виды цветников", route: "/main/flower_garden" },
                { title: "Вредители", route: "/main/vermins" },
                { title: "Болезни", route: "/main/diseases" },
                { title: "Статусы", route: "/main/statuses" },
                {
                  title: "Хозяйственные мероприятия",
                  route: "/main/events"
                },
                {
                  title: "Санитарное состояние",
                  route: "/main/sanitary"
                },
                {
                  title: "Категории насаждений",
                  route: "/main/categories"
                },
              ]
            },
            {
              title: "Объекты благоустройства",
              sections: [
                { title: "Архитектурные формы", route: "/main/architectureTypes" },
                { title: "Фонтаны", route: "/main/fountains" }
              ]
            },
            {
              title: "Водные объекты",
              sections: [
                { title: "Каналы", route: "/main/channels" },
                { title: "Точка", route: "/main/point" },
                { title: "Коллекторы", route: "/main/collectors" },
                { title: "Гидротехнические сооружения", route: "/main/manufactures" },
                { title: "Арычная сеть", route: "/main/aryki" },
                { title: "Общий справочник", route: "/main/water_general" },
                { title: "Реки", route: "/main/rivers" },
              ]
            },
            {
              title: "Почва",
              sections: [
                { title: "Назначение", route: "/main/soilPurpose" }
              ]
            },
            {
              title: "Отходы",
              sections: [
                { title: "Тип контейнерной площадки", route: "/main/containerType" },
                { title: "Зона для КГО", route: "/main/cgo" },
                { title: "Основание площадки", route: "/main/fundament" },
                { title: "Принадлежность", route: "/main/relative" },
                { title: "Ограждение", route: "/main/fence" },
                { title: "Расширение", route: "/main/expand" },
                { title: "Для фракций ТКО", route: "/main/tko" },
                { title: "Материал контейнера", route: "/main/material" },
                { title: "Навес", route: "/main/naves" },
                { title: "Контейнеры", route: "/main/container" },
                { title: "График вывоза", route: "/main/grafic" },
              ]
            }
          ]
        }
      ]
    };
  },

  methods: {
    ...mapActions([
      "getDistrictsData",
      "getBreedsData",
      "getLayersData",
      "getTypesData",
      "getFlowersData",
      "getVinesData",
      "getFlowerCategoryData",
      "getFlowerGardenData",
      "getVerminsData",
      "getDiseasesData",
      "getStatusesData",
      "getEventsData",
      "getSanitaryData",
      "getCategoriesData",
      "getUsersData",
      "getRolesData",
      "getBushData",
      "getPlantByExcel",
      "getArchitectureType",
      "getAppeals",
      "getEventLog",
      "logoutUser",
      "getLogUsers"
    ]),
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("useDarkTheme", this.$vuetify.theme.dark.toString());
    },
    async logout() {
      await this.logoutUser();
    },
    changeDrawerLeft() {
      if (this.$vuetify.breakpoint.mobile) {
        this.drawerLeft = !this.drawerLeft;
      } else {
        this.expand = !this.expand;
      }
    },
    changeDrawerRight() {
      this.drawerRight = !this.drawerRight;
    },
    closeDrawerLeft() {
      this.expand = !this.expand;
    },
    layersIcon() {
      if (
        this.$route.path === "/main/map" ||
        this.$route.path === "/main/districts" ||
        this.$route.path === "/main/clustergrid" ||
        this.$route.path === "/main/taxatorgrid" ||
        this.$route.path === "/main/sanitizinggrid"
      ) {
        this.iconDrawerRight = true;
      } else {
        this.iconDrawerRight = false;
        this.drawerRight = false;
      }
    },
    settingsPage() {
      const path = `/main/settings`;
      if (this.$route.path !== path) this.$router.push("/main/settings");
    }
  },

  created() {
    this.getDistrictsData();
    this.getBreedsData();
    this.getLayersData();
    this.getTypesData();
    this.getFlowersData();
    this.getVinesData();
    this.getFlowerCategoryData();
    this.getFlowerGardenData();
    this.getVerminsData();
    this.getDiseasesData();
    this.getStatusesData();
    this.getEventsData();
    this.getSanitaryData();
    this.getCategoriesData();
    this.getUsersData();
    this.getRolesData();
    this.getBushData();
    this.getArchitectureType();
    if (!this.$vuetify.breakpoint.mobile) {
      this.mini = true;
      this.drawerLeft = true;
    }
    this.layersIcon();
    this.getAppeals();
    this.getEventLog();
    this.getLogUsers();


  },

  watch: {
    $route: function() {
      this.layersIcon();
    },
    maps: {
      deep: true,
      handler(array) {
        this.$store.commit("updateMaps", array);
      }
    }
  },
  async mounted() {
    const theme = localStorage.getItem("useDarkTheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else this.$vuetify.theme.dark = false;
    }
    await this.$store.dispatch("getCurrentUser");
    const user = JSON.parse(JSON.stringify(this.$store.state.user.currentUser));
    console.log(user);
    this.fullName = user.surname.toUpperCase() + " " + user.name.toUpperCase();
  }
};
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
.bg {
  background: #75a4ff;
  background: -webkit-linear-gradient(to bottom, #75a4ff, #234b83);
  background: linear-gradient(to bottom, #75a4ff, #234b83);
}
::v-deep .v-navigation-drawer {
  transition-duration: 0.5s;
}
.desktop {
  margin-left: 56px;
}
::v-deep .v-navigation-drawer .v-list:not(.v-select-list) {
  margin-top: 15px;
}
</style>
